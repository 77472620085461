function updateSideScroller() {
  jQuery(".side-scroll").each(function() {
    var scroller = jQuery(this);
    //setTimeout(function () {
    var scroll_controls = jQuery(this).find(".scroll-controls");
    var id = jQuery(".t-controls").attr("id");
    var leftPos = jQuery(this)
      .find(".container-fluid.scrollable")
      .scrollLeft();

    //console.log(leftPos);

    // If we are all the way to left hide that arrow.
    if (leftPos <= 0) {
      scroll_controls.find("a.back").addClass("out");
    } else {
      scroll_controls.find("a.back").removeClass("out");
    }

    // Check if we're scrolled all the way to right?
    var viewable_width = scroller.find(".row").width();
    var scrollable_width = scroller.find(".row")[0].scrollWidth;
    console.log("Scrollable width is " + scrollable_width);
    //console.log("Viewable width " + viewable_width);
    //console.log("Left position is  " + leftPos);

    if (leftPos + viewable_width + 10 >= scrollable_width) {
      scroller.find("a.forward").addClass("out");
    } else {
      scroller.find("a.forward").removeClass("out");
    }
    //}, 210);
  });
}

// Testimonials scroll
jQuery(document).ready(function() {
  updateSideScroller();
  jQuery(".side-scroll .container-fluid.scrollable").scroll(
    jQuery.throttle(65, updateSideScroller)
  );
  jQuery(".side-scroll a.forward").click(function() {
    var id = jQuery(this)
      .parent()
      .attr("id");
    var leftPos = jQuery("#container-" + id).scrollLeft();

    // Get the size of the first div
    var first_div = jQuery("#container-" + id).find('div[class*="col-"]');
    var scroll_distance = first_div.width();

    jQuery("#container-" + id).animate(
      { scrollLeft: leftPos + scroll_distance },
      200
    );
    updateSideScroller();
    return false;
  });

  jQuery(".side-scroll a.back").click(function() {
    var id = jQuery(this)
      .parent()
      .attr("id");
    var leftPos = jQuery("#container-" + id).scrollLeft();

    // Get the size of the first div
    var first_div = jQuery("#container-" + id).find('div[class*="col-"]');
    var scroll_distance = first_div.width();

    jQuery("#container-" + id).animate(
      { scrollLeft: leftPos - scroll_distance },
      200
    );
    updateSideScroller();
    return false;
  });
});
